var breakPoint = 1000;

$(document).ready(function(){
  if ( $( window ).width() > breakPoint ){
    $(".header").addClass('desktop').removeClass('mobile');
  } else {
    $(".header").addClass('mobile').removeClass('desktop');
  }
  $( window ).resize(function() {
    if ( $( window ).width() > breakPoint ){
      $(".header").addClass('desktop').removeClass('mobile');
      if($('.header').hasClass('header-2')){
        $('footer').show();
        $('main').show();
        $("#nav").show();
        $('.header').css('position', 'fixed');
      }
    } else {
      $(".header").addClass('mobile').removeClass('desktop');
    }
  });


  $('#pull').click(function(e){
    e.preventDefault();
    if ( $("#nav").css("display") == "none" ){
      $("#nav").show();
      $('#pull').addClass('open');
      if($('.header').hasClass('header-2')){
        $('footer').hide();
        $('main').hide();
        $('.content-header').hide();
        $('.header').css('position', 'static');
      }

    } else {
      $("#nav").hide();
      $(".lvl-1").parent().children("div").children("ul").hide();
      $('#pull').removeClass('open');
      if($('.header').hasClass('header-2')){
        $('footer').show();
        $('main').show();
        $('.content-header').show();
        $('.header').css('position', 'fixed');
      }

    }
  });

  $(".lvl-1").click(function(e){
    if($( window ).width() <= breakPoint){
      e.preventDefault();
      if($(this).parent().has(".lvl-2").length){
        if($(this).parent().children("div").children("ul").css("display") == "none"){
          $(".lvl-1").parent().children("div").children("ul").slideUp();
          $(this).parent().children("div").children("ul").slideDown();
        }
      }
      else{
        location.href=$(this).attr("href");
      }
    }

  });
});