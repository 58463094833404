$(document).ready(function(){
  $('.accordion-title').click(function(event) {
    event.preventDefault();
    $(this).toggleClass('open');

    $accordion_title = $(this);
    $accordion_content = $(this).next('.accordion-content');
    $('.accordion-content').not($accordion_content).slideUp();
    $('.accordion-content').not($accordion_content).prev('.accordion-title').removeClass('open');
    $accordion_content.stop(true, true).slideToggle(400);
  });
});