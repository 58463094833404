
$(window).scroll(function(){
  if($(window).scrollTop() > 700){
    $('.ak-cta-top').fadeIn();
  }else{
    $('.ak-cta-top').fadeOut()
  }
});
$(document).ready(function(){

  if (location.hash) {
    var anchor = location.hash.split('#')[1];
    var elmnt = document.getElementById(anchor);

    if(elmnt){
      $("html, body").animate({
        scrollTop: elmnt.offsetTop
      }, 0);
      elmnt.click();
    }
  }

  $("[data-anchor]").click(function(e) {
    e.preventDefault();
    var anchor = $(this).data('anchor');
    var elmnt = document.getElementById(anchor);
    $('html, body').animate({
      scrollTop: elmnt.offsetTop
    }, 500);
  });

  $(".ak-cta-top").click(function(e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 500);
  });

  $('#datetimepickerStartDate').datetimepicker({
    format: 'DD-MM-YYYY'
  });
  $('#datetimepickerStartTime').datetimepicker({
    format: 'HH:mm'
  });
  $('#datetimepickerEndDate').datetimepicker({
    format: 'DD-MM-YYYY'
  });
  $('#datetimepickerEndTime').datetimepicker({
    format: 'HH:mm'
  });

  $('#contactform').submit(function(e){
    var arr = [],
        text;
    if(!$('#firma').val()) {
      arr.push('bitte Firma angeben');
      $('#firma').addClass('borderred');
      $('.labelhide.firma').show();
    }
    if(!$('#name').val()) {
      arr.push('<br>bitte Name angeben');
      $('#name').addClass('borderred');
      $('.labelhide.name').show();
    }
    if(!$('#tel').val()) {
      arr.push('<br>bitte Telefon Nummer angeben');
      $('#tel').addClass('borderred');
      $('.labelhide.tel').show();
    }
    if(!$('#mail').val()) {
      arr.push('<br>ungültige E-Mail Adresse');
      $('#mail').addClass('borderred');
      $('.labelhide.mail').show();
    }

    text = arr.toString();
    $('#alert-container').html('Bitte alle obligatorischen Felder ausfüllen');
    if(arr.length){
      e.preventDefault();
    } else {
      var button = $('#contactButton');
      button.html(button.data('sendtext') + ' <i class="fa fa-spinner fa-spin"></i>');
      button.prop('disabled', true);
    }



  }); 

});


